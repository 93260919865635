var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "landing-page-wrap relative" },
    [
      _c("Header", {
        attrs: {
          headerFixed: _vm.headerFixed,
          activeNav: _vm.activeNav,
          isMobile: _vm.isMobile,
        },
        on: {
          "on-select": _vm.onSelectNav,
          "show-qrcode": _vm.onShowQrcode,
          "show-mobile-nav": _vm.onShowMobileNav,
        },
      }),
      _c(
        "div",
        {
          ref: "scrollview",
          staticClass: "landing-page",
          on: { scroll: _vm.watchScroll },
        },
        [
          _c("div", { staticClass: "banner", attrs: { id: "banner" } }, [
            _c("div", { staticClass: "content" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.banner.title)),
              ]),
              _c("p", { staticClass: "desc" }, [
                _vm._v(_vm._s(_vm.banner.desc)),
              ]),
              _c(
                "div",
                {
                  staticClass: "btn cur-pointer",
                  attrs: { id: "free-try2" },
                  on: { click: _vm.toIndex },
                },
                [
                  _c("span", [_vm._v("免费试用")]),
                  !_vm.isMobile
                    ? _c("img", {
                        staticClass: "arrow",
                        attrs: {
                          src: _vm.getImgUrl("arrow_right_white"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "total bg1" }, [
            _c("div", { staticClass: "relative total-content" }, [
              _c(
                "div",
                { staticClass: "content absolute display-flex flex-wrap-wrap" },
                _vm._l(_vm.totalList, function (total) {
                  return _c(
                    "div",
                    {
                      key: total.key,
                      staticClass: "item display-flex align-items-center",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.getImgUrl(`total_${total.key}`),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "right" }, [
                        !_vm.isMobile
                          ? _c("p", { staticClass: "title" }, [
                              _vm._v(_vm._s(total.title)),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "num" }, [
                          _vm._v(" " + _vm._s(total.num) + " "),
                          _c("span", [_vm._v(_vm._s(total.unit))]),
                        ]),
                        _vm.isMobile
                          ? _c("p", { staticClass: "title" }, [
                              _vm._v(_vm._s(total.title)),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._l(_vm.introductionList, function (introduction, index) {
            return _c(
              "div",
              {
                key: introduction.key,
                staticClass: "introduction relative",
                class: {
                  bg1: index % 2 === 0,
                  bg2: index % 2 !== 0,
                },
                attrs: { id: !_vm.isMobile ? introduction.key : "" },
              },
              [
                _c("div", {
                  staticClass: "mobile-id absolute",
                  attrs: { id: _vm.isMobile ? introduction.key : "" },
                }),
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(introduction.title)),
                ]),
                _c("p", { staticClass: "desc" }, [
                  _vm._v(_vm._s(introduction.desc)),
                ]),
                _c("p", { staticClass: "en-title absolute" }, [
                  _vm._v(_vm._s(introduction.enTitle)),
                ]),
                _c("div", { staticClass: "content" }, [
                  introduction.key === "product"
                    ? _c("div", { staticClass: "product-service" }, [
                        _c(
                          "div",
                          { staticClass: "data-capability g-data-capability" },
                          [
                            !_vm.isMobile
                              ? _c(
                                  "div",
                                  _vm._l(_vm.newProductList, function (data) {
                                    return _c("div", { key: data.key }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "subtitle",
                                          class: {
                                            "mt-70": data.key === "product",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "relative" },
                                            [_vm._v(_vm._s(data.title))]
                                          ),
                                        ]
                                      ),
                                      data.key === "majorFunction"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "g-home-custom-pagination",
                                                  attrs: { slot: "pagination" },
                                                  slot: "pagination",
                                                },
                                                [
                                                  _c(
                                                    "nav",
                                                    _vm._l(
                                                      _vm.majorFunctionItems,
                                                      function (gitem, index) {
                                                        return _c(
                                                          "a",
                                                          {
                                                            key: index,
                                                            class: {
                                                              "gg-active":
                                                                index ===
                                                                _vm.majorFuncActiveIndex,
                                                            },
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.majorGoToSlide(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                gitem
                                                                  .figureCaption
                                                                  .title
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "swiper",
                                                {
                                                  key: "majorFuncSwiper",
                                                  staticClass:
                                                    "g-swiper-container g-major-func-items",
                                                  attrs: {
                                                    id: "majorFuncSwiper",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.majorFunctionItems,
                                                  function (item, slideIndex) {
                                                    return _c(
                                                      "swiper-slide",
                                                      { key: item.id },
                                                      [
                                                        _c("figure", [
                                                          _c(
                                                            "figcaption",
                                                            [
                                                              _c("h4", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .figureCaption
                                                                      .title
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._l(
                                                                item
                                                                  .figureCaption
                                                                  .desc,
                                                                function (
                                                                  text,
                                                                  tIndex
                                                                ) {
                                                                  return _c(
                                                                    "p",
                                                                    {
                                                                      key: tIndex,
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            text.dl
                                                                          ) +
                                                                          _vm._s(
                                                                            text.dd
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "tags",
                                                                },
                                                                _vm._l(
                                                                  item
                                                                    .figureCaption
                                                                    .tags,
                                                                  function (
                                                                    tag,
                                                                    tagIndex
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          "tag_" +
                                                                          tagIndex,
                                                                        staticClass:
                                                                          "tag",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              tag
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "g-video-wrap-container",
                                                            },
                                                            [
                                                              _c("video", {
                                                                attrs: {
                                                                  src: item.figureVideoSrc,
                                                                  alt: item
                                                                    .figureCaption
                                                                    .title,
                                                                  controls: "",
                                                                  muted: "",
                                                                  loop: "",
                                                                  controlsList:
                                                                    "nodownload",
                                                                  autoplay: "",
                                                                },
                                                                domProps: {
                                                                  muted: true,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                              _c("div", {
                                                staticClass:
                                                  "swiper-pagination",
                                                attrs: { slot: "pagination" },
                                                slot: "pagination",
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      data.key === "storeLocation"
                                        ? _c(
                                            "div",
                                            {
                                              attrs: {
                                                id: "majorVideoContainer",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "g-home-custom-pagination",
                                                  attrs: { slot: "pagination" },
                                                  slot: "pagination",
                                                },
                                                [
                                                  _c(
                                                    "nav",
                                                    _vm._l(
                                                      _vm.storeLocationItems,
                                                      function (gitem, index) {
                                                        return _c(
                                                          "a",
                                                          {
                                                            key: index,
                                                            class: {
                                                              "gg-active":
                                                                index ===
                                                                _vm.activeIndex,
                                                            },
                                                            attrs: {
                                                              href: "javascript:void(0);",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.goToSlide(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                gitem
                                                                  .figureCaption
                                                                  .title
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "swiper",
                                                {
                                                  key: "gSwiperContainer",
                                                  staticClass:
                                                    "g-swiper-container",
                                                  attrs: { id: "majorSwiper" },
                                                },
                                                _vm._l(
                                                  _vm.storeLocationItems,
                                                  function (item, slideIndex) {
                                                    return _c(
                                                      "swiper-slide",
                                                      { key: item.id },
                                                      [
                                                        _c("figure", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "g-video-wrap-container",
                                                            },
                                                            [
                                                              _c("video", {
                                                                attrs: {
                                                                  src: item.figureVideoSrc,
                                                                  alt: item
                                                                    .figureCaption
                                                                    .title,
                                                                  controls: "",
                                                                  muted: "",
                                                                  loop: "",
                                                                  controlsList:
                                                                    "nodownload",
                                                                  autoplay:
                                                                    "index === activeIndex",
                                                                },
                                                                domProps: {
                                                                  muted: true,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "figcaption",
                                                            [
                                                              _c("h4", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .figureCaption
                                                                      .title
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._l(
                                                                item
                                                                  .figureCaption
                                                                  .desc,
                                                                function (
                                                                  text,
                                                                  tIndex
                                                                ) {
                                                                  return _c(
                                                                    "p",
                                                                    {
                                                                      key: tIndex,
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            text.dl
                                                                          ) +
                                                                          _vm._s(
                                                                            text.dd
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                              _c("div", {
                                                staticClass:
                                                  "swiper-pagination",
                                                attrs: { slot: "pagination" },
                                                slot: "pagination",
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      data.key === "customReports"
                                        ? _c(
                                            "div",
                                            { staticClass: "g-figure-list" },
                                            _vm._l(
                                              _vm.productsIntros,
                                              function (intro, index) {
                                                return _c(
                                                  "figure",
                                                  {
                                                    key: index,
                                                    staticClass: "g-figure-img",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: intro.src,
                                                        width: "184",
                                                      },
                                                    }),
                                                    _c("figcaption", [
                                                      _vm._v(
                                                        _vm._s(intro.title)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : introduction.key === "data"
                    ? _c("div", { staticClass: "data-capability" }, [
                        !_vm.isMobile
                          ? _c(
                              "div",
                              _vm._l(_vm.dataList, function (data) {
                                return _c("div", { key: data.key }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "subtitle",
                                      class: {
                                        "mt-70": data.key === "product",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "relative" }, [
                                        _vm._v(_vm._s(data.title)),
                                      ]),
                                    ]
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.getImgUrl(`data_${data.key}`),
                                      alt: "",
                                    },
                                  }),
                                ])
                              }),
                              0
                            )
                          : _c(
                              "div",
                              _vm._l(_vm.dataList, function (data) {
                                return _c(
                                  "div",
                                  { key: data.key },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "subtitle",
                                        style: {
                                          marginTop:
                                            data.key === "index"
                                              ? "0.2rem"
                                              : "4rem",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "relative" },
                                          [
                                            _c("img", {
                                              staticClass: "icon absolute",
                                              attrs: {
                                                src: _vm.getImgUrl(
                                                  "subtitle_icon"
                                                ),
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(
                                              " " + _vm._s(data.title) + " "
                                            ),
                                            _c("img", {
                                              staticClass: "icon1 absolute",
                                              attrs: {
                                                src: _vm.getImgUrl(
                                                  "subtitle_icon"
                                                ),
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    data.key === "index"
                                      ? _c("customSwiper", {
                                          attrs: {
                                            imgs: _vm.getSwiperImgs(
                                              `data_${data.key}`,
                                              3
                                            ),
                                            interval: 3000,
                                          },
                                        })
                                      : _c("img", {
                                          attrs: {
                                            src: _vm.getImgUrl(
                                              `data_${data.key}`
                                            ),
                                            alt: "",
                                          },
                                        }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                      ])
                    : introduction.key === "industry"
                    ? _c("div", { staticClass: "industry-solutions" }, [
                        _c(
                          "div",
                          {
                            class: {
                              "home-main": !_vm.isMobile,
                              "display-flex": !_vm.isMobile,
                            },
                          },
                          [
                            _c("div", { staticClass: "left relative" }, [
                              _vm.isMobile
                                ? _c("img", {
                                    staticClass: "absolute",
                                    attrs: {
                                      src: _vm.getImgUrl("industry_bg"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _c("p", { staticClass: "title relative" }, [
                                _vm._v(_vm._s(_vm.industryObj.left.title)),
                              ]),
                              _c(
                                "div",
                                {
                                  class: {
                                    "display-flex": _vm.isMobile,
                                    "flex-wrap-wrap": _vm.isMobile,
                                  },
                                },
                                _vm._l(
                                  _vm.industryObj.left.content,
                                  function (bm, bmIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: "bm_" + bmIndex,
                                        staticClass: "item relative",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-left" },
                                          [
                                            _c("img", {
                                              staticClass: "circle",
                                              attrs: {
                                                src: _vm.getImgUrl(
                                                  "industry_circle"
                                                ),
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-right" },
                                          [_vm._v(_vm._s(bm))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "right" },
                              _vm._l(
                                _vm.industryObj.right,
                                function (industry, industryIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: "industry_" + industryIndex,
                                      staticClass: "item relative",
                                      class: {
                                        "mb-25":
                                          !_vm.isMobile && industryIndex < 2,
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "left-icon absolute",
                                        attrs: {
                                          src: _vm.getImgUrl("industry_icon"),
                                          alt: "",
                                        },
                                      }),
                                      _c("img", {
                                        staticClass: "right-icon absolute",
                                        attrs: {
                                          src: _vm.getImgUrl(
                                            `industry_${industry.key}`
                                          ),
                                          alt: "",
                                        },
                                      }),
                                      _c("p", { staticClass: "title mb-20" }, [
                                        _vm._v(_vm._s(industry.title)),
                                      ]),
                                      _c("div", { staticClass: "content" }, [
                                        _vm._v(_vm._s(industry.content)),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ])
                    : introduction.key === "case"
                    ? _c("div", { staticClass: "customer-cases" }, [
                        _c(
                          "div",
                          {
                            ref: "caseHeader",
                            refInFor: true,
                            staticClass: "case-header",
                          },
                          _vm._l(_vm.caseList, function (cusCase, caseIndex) {
                            return _c(
                              "div",
                              {
                                key: cusCase.key,
                                staticClass: "case-item",
                                class: {
                                  active: caseIndex === _vm.caseCurIndex,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onChangeCase(caseIndex)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "case-img",
                                  attrs: {
                                    src: _vm.getImgUrl(
                                      `${cusCase.key}${
                                        caseIndex === _vm.caseCurIndex
                                          ? "_active"
                                          : ""
                                      }_icon`
                                    ),
                                    alt: "",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "case-title",
                                    class: {
                                      active: caseIndex === _vm.caseCurIndex,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(cusCase.title) + " ")]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "case-content relative" }, [
                          _c("img", {
                            staticClass: "case-img",
                            attrs: {
                              src: _vm.getImgUrl(
                                `${_vm.caseCurContent.key}_bg`
                              ),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "right relative" }, [
                            _c(
                              "div",
                              { staticClass: "right-content" },
                              [
                                _c("p", { staticClass: "title" }, [
                                  _vm._v("项目背景"),
                                ]),
                                _c("div", { staticClass: "item mb-20" }, [
                                  _vm._m(0, true),
                                  _c("div", { staticClass: "item-right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.caseCurContent.projectBackground
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("p", { staticClass: "title" }, [
                                  _vm._v("解决方案"),
                                ]),
                                _vm.caseCurContent.solution?.title
                                  ? _c("div", { staticClass: "item" }, [
                                      _c("div", { staticClass: "item-right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.caseCurContent.solution.title
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._l(
                                  _vm.caseCurContent.solution.content,
                                  function (solution, solutionIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: "solution_" + solutionIndex,
                                        staticClass: "item",
                                      },
                                      [
                                        _vm._m(1, true),
                                        _c(
                                          "div",
                                          { staticClass: "item-right" },
                                          [_vm._v(_vm._s(solution))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _c("p", { staticClass: "title mt-20" }, [
                                  _vm._v("客户收益"),
                                ]),
                                _c("div", { staticClass: "item" }, [
                                  _vm._m(2, true),
                                  _c("div", { staticClass: "item-right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.caseCurContent.customerEarning
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "service-customer" }, [
                          _c("div", { staticClass: "subtitle" }, [
                            _c("span", { staticClass: "relative" }, [
                              _vm.isMobile
                                ? _c("img", {
                                    staticClass: "icon absolute",
                                    attrs: {
                                      src: _vm.getImgUrl("subtitle_icon"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" 服务客户 "),
                              _vm.isMobile
                                ? _c("img", {
                                    staticClass: "icon1 absolute",
                                    attrs: {
                                      src: _vm.getImgUrl("subtitle_icon"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "rolling-wrap relative" }, [
                            !_vm.isMobile
                              ? _c("img", {
                                  staticClass: "mask1 absolute",
                                  attrs: {
                                    src: _vm.getImgUrl("service_mask"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "rolling-img" }, [
                              _c("img", {
                                staticClass: "rolling",
                                attrs: {
                                  src: _vm.getImgUrl("service_customer"),
                                  alt: "",
                                },
                              }),
                              _c("img", {
                                staticClass: "rolling rolling2",
                                attrs: {
                                  src: _vm.getImgUrl("service_customer"),
                                  alt: "",
                                },
                              }),
                            ]),
                            !_vm.isMobile
                              ? _c("img", {
                                  staticClass: "mask2 absolute",
                                  attrs: {
                                    src: _vm.getImgUrl("service_mask"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]
            )
          }),
          _c("div", { attrs: { id: "freeTrial" } }, [
            !_vm.isMobile
              ? _c("div", { staticClass: "free-trial" }, [
                  _c("p", { staticClass: "trial-title" }, [_vm._v("申请试用")]),
                  _c("p", { staticClass: "trial-sub-title" }, [
                    _vm._v(" 留下您的联系方式，我们专业顾问会尽快与您联系 "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form" },
                    _vm._l(_vm.formList, function (form, formIndex) {
                      return _c(
                        "div",
                        { key: "form_" + formIndex },
                        [
                          !form.isTextarea
                            ? _c(
                                "div",
                                {
                                  staticClass: "form-item",
                                  class: {
                                    "mb-20": !_vm.showErr,
                                    "mb-10": _vm.showErr,
                                  },
                                },
                                [
                                  _c("span", { staticClass: "required" }, [
                                    _vm._v("*"),
                                  ]),
                                  _c("label", [
                                    _vm._v(_vm._s(form.placeholder)),
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: form.value,
                                        expression: "form.value",
                                      },
                                    ],
                                    domProps: { value: form.value },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          form,
                                          "value",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              )
                            : _c("Input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: form.placeholder,
                                },
                                model: {
                                  value: form.value,
                                  callback: function ($$v) {
                                    _vm.$set(form, "value", $$v)
                                  },
                                  expression: "form.value",
                                },
                              }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showErr,
                                  expression: "showErr",
                                },
                              ],
                              staticClass: "form-item-err mb-10 ml-20",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formError[form.key] || "") +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.submitLoading,
                          expression: "submitLoading",
                        },
                      ],
                      staticClass: "trial-btn",
                      attrs: { "data-agl-cvt": "5", id: "btn_submit" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v(" 提交 ")]
                  ),
                ])
              : _vm._e(),
          ]),
          _c("Footer", { attrs: { isMobile: _vm.isMobile } }),
        ],
        2
      ),
      _vm.isShowQrcode
        ? _c("div", { staticClass: "qrcode-wrap absolute" }, [
            _c("div", { staticClass: "qrcode" }, [
              _c("img", { attrs: { src: _vm.getImgUrl("qrcode"), alt: "" } }),
              _c("div", [_vm._v("千里目小助手")]),
            ]),
            _c("img", {
              staticClass: "close_btn",
              attrs: { src: _vm.getImgUrl("close_white"), alt: "" },
              on: {
                click: function ($event) {
                  return _vm.onShowQrcode(false)
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.isShowMobileNav
        ? _c("div", { staticClass: "nav-wrap absolute" }, [
            _c("div", {
              staticClass: "blank absolute",
              on: {
                click: function ($event) {
                  return _vm.onShowMobileNav(false)
                },
              },
            }),
            _c("div", { staticClass: "nav" }, [
              _c(
                "ul",
                [
                  _vm._l(_vm.introductionList, function (introduction) {
                    return _c("li", { key: introduction.key }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `#${introduction.key}`,
                            target: "_self",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onSelectNav(introduction.key)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(introduction.title) + " ")]
                      ),
                    ])
                  }),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://changjing.com.cn",
                          target: "_blank",
                        },
                        on: { click: _vm.onSelectNav },
                      },
                      [_vm._v(" 亿景官网 ")]
                    ),
                  ]),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-left" }, [
      _c("div", { staticClass: "circle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-left" }, [
      _c("div", { staticClass: "circle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-left" }, [
      _c("div", { staticClass: "circle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }