var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "logo" }, [
        _vm._m(0),
        !_vm.isMobile
          ? _c("p", { staticClass: "phone" }, [_vm._v("010-86463634")])
          : _vm._e(),
        _vm.qrcode && !_vm.isMobile
          ? _c("div", { staticClass: "qr-code" }, [
              _c("img", { attrs: { src: _vm.qrcode, alt: "" } }),
              _c("p", [_vm._v("扫描二维码关注公众号")]),
            ])
          : _vm._e(),
      ]),
      _c(
        "ul",
        { staticClass: "foot-nav" },
        _vm._l(_vm.footNavList, function (nav, i) {
          return _c("li", { key: "foot_nav_" + i }, [
            nav.path
              ? _c(
                  "a",
                  {
                    staticClass: "foot-nav-cate",
                    attrs: {
                      href: `https://changjing.com.cn${nav.path}`,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(nav.name))]
                )
              : _c("a", { staticClass: "foot-nav-cate" }, [
                  _vm._v(_vm._s(nav.name)),
                ]),
            _c(
              "ul",
              { staticClass: "foot-nav-children" },
              _vm._l(nav.children, function (item, index) {
                return _c("li", { key: "foot_nav_" + i + "_" + index }, [
                  item.path.indexOf("http") > -1
                    ? _c(
                        "a",
                        { attrs: { href: item.path, target: "_blank" } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    : _c(
                        "a",
                        {
                          attrs: {
                            href: `https://changjing.com.cn${item.path}`,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                ])
              }),
              0
            ),
          ])
        }),
        0
      ),
      _vm.isMobile
        ? _c("div", { staticClass: "contact-wrap" }, [
            _c("img", {
              staticClass: "qrcode",
              attrs: { src: _vm.qrcode, alt: "" },
            }),
            _c("p", [_vm._v("扫描二维码关注亿景智联公众号")]),
            _c("p", [_vm._v("010-86463634")]),
          ])
        : _vm._e(),
      _vm.isMobile
        ? _c("div", { staticClass: "copyright" }, [_vm._m(1)])
        : _c("div", { staticClass: "copyright" }, [
            _c("p", [
              _vm._v(
                " Copyright ©2020 changjing.com.cn Inc. All rights reserved "
              ),
            ]),
            _vm._m(2),
            _c("p", { staticClass: "mt-10" }, [
              _vm._v(" 亿景智联（苏州）科技有限公司 | 010-86463634(客服经理) "),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { attrs: { href: "https://changjing.com.cn", target: "_blank" } },
      [_c("img", { attrs: { src: "/images/yj_logo_blue.png", alt: "" } })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" Copyright ©2020 changjing.com.cn Inc. All rights reserved | "),
      _c(
        "a",
        { attrs: { target: "_blank", href: "https://beian.miit.gov.cn/" } },
        [_vm._v("苏ICP备2023018419号-1 ")]
      ),
      _vm._v(" | 亿景智联（苏州）科技有限公司 | 010-86463634(客服经理) "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mt-10" }, [
      _c(
        "a",
        { attrs: { target: "_blank", href: "https://beian.miit.gov.cn/" } },
        [_vm._v("苏ICP备2023018419号-1 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }