<template>
  <div class="landing-page-wrap relative">
    <Header
      :headerFixed="headerFixed"
      :activeNav="activeNav"
      :isMobile="isMobile"
      @on-select="onSelectNav"
      @show-qrcode="onShowQrcode"
      @show-mobile-nav="onShowMobileNav"
    ></Header>

    <div class="landing-page" ref="scrollview" @scroll="watchScroll">
      <div class="banner" id="banner">
        <div class="content">
          <p class="title">{{ banner.title }}</p>
          <p class="desc">{{ banner.desc }}</p>
          <div id="free-try2" class="btn cur-pointer" @click="toIndex">
            <span>免费试用</span>
            <img
              class="arrow"
              :src="getImgUrl('arrow_right_white')"
              alt=""
              v-if="!isMobile"
            />
          </div>
        </div>
      </div>

      <div class="total bg1">
        <div class="relative total-content">
          <div class="content absolute display-flex flex-wrap-wrap">
            <div
              v-for="total in totalList"
              :key="total.key"
              class="item display-flex align-items-center"
            >
              <img :src="getImgUrl(`total_${total.key}`)" alt="" />
              <div class="right">
                <p class="title" v-if="!isMobile">{{ total.title }}</p>
                <div class="num">
                  {{ total.num }}
                  <span>{{ total.unit }}</span>
                </div>
                <p class="title" v-if="isMobile">{{ total.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(introduction, index) in introductionList"
        :key="introduction.key"
        :id="!isMobile ? introduction.key : ''"
        class="introduction relative"
        :class="{
          bg1: index % 2 === 0,
          bg2: index % 2 !== 0,
        }"
      >
        <div
          :id="isMobile ? introduction.key : ''"
          class="mobile-id absolute"
        ></div>
        <p class="title">{{ introduction.title }}</p>
        <p class="desc">{{ introduction.desc }}</p>
        <p class="en-title absolute">{{ introduction.enTitle }}</p>
        <div class="content">
          <div v-if="introduction.key === 'product'" class="product-service">
            <!-- <div
              v-for="(product, productIndex) in productList"
              :key="product.key"
              class="product-item"
              :style="{height: !isMobile ? product.height : 'auto'}"
            >
              <div
                class="product-text"
                :class="{
                  fr: productIndex % 2 !== 0 && !isMobile,
                  fl: productIndex % 2 === 0 && !isMobile
                }"
              >
                <p class="title" :class="{'mb-20': isMobile}">{{ product.title }}</p>
                <div>
                  <div
                    v-for="(desc, descIndex) in product.desc"
                    :key="'desc_' + descIndex"
                    class="desc"
                  >
                    {{ desc }}
                  </div>
                </div>
                <div class="tags">
                  <div
                    v-for="(tag, tagIndex) in product.tags"
                    :key="'tag_' + tagIndex"
                    class="tag"
                  >
                    {{ tag }}
                  </div>
                </div>
              </div>
              <img
                :src="getImgUrl(`product_${product.key}`)"
                :class="{
                  fr: productIndex % 2 === 0 && !isMobile,
                  fl: productIndex % 2 !== 0 && !isMobile
                }"
              />
            </div> -->
            <div class="data-capability g-data-capability">
              <div v-if="!isMobile">
                <div v-for="data in newProductList" :key="data.key">
                  <div
                    class="subtitle"
                    :class="{ 'mt-70': data.key === 'product' }"
                  >
                    <span class="relative">{{ data.title }}</span>
                  </div>
                  <div v-if="data.key === 'majorFunction'">
                    <div class="g-home-custom-pagination" slot="pagination">
                      <nav>
                        <a
                          v-for="(gitem, index) in majorFunctionItems"
                          :key="index"
                          href="javascript:void(0);"
                          @click="majorGoToSlide(index)"
                          :class="{
                            'gg-active': index === majorFuncActiveIndex,
                          }"
                          >{{ gitem.figureCaption.title }}</a
                        >
                      </nav>
                    </div>
                    <swiper
                      class="g-swiper-container g-major-func-items"
                      id="majorFuncSwiper"
                      key="majorFuncSwiper"
                    >
                      <swiper-slide
                        v-for="(item, slideIndex) in majorFunctionItems"
                        :key="item.id"
                      >
                        <!-- 这里放置轮播内容 -->
                        <figure>
                          <figcaption>
                            <h4>{{ item.figureCaption.title }}</h4>
                            <p
                              v-for="(text, tIndex) in item.figureCaption.desc"
                              :key="tIndex"
                            >
                              {{ text.dl }}{{ text.dd }}
                            </p>
                            <div class="tags">
                              <div
                                v-for="(tag, tagIndex) in item.figureCaption
                                  .tags"
                                :key="'tag_' + tagIndex"
                                class="tag"
                              >
                                {{ tag }}
                              </div>
                            </div>
                          </figcaption>
                          <div class="g-video-wrap-container">
                            <video
                              :src="item.figureVideoSrc"
                              :alt="item.figureCaption.title"
                              controls
                              muted
                              loop
                              controlsList="nodownload"
                              autoplay
                            ></video>
                          </div>
                        </figure>
                      </swiper-slide>
                    </swiper>
                    <div class="swiper-pagination" slot="pagination"></div>
                  </div>
                  <div
                    v-if="data.key === 'storeLocation'"
                    id="majorVideoContainer"
                  >
                    <div class="g-home-custom-pagination" slot="pagination">
                      <nav>
                        <a
                          v-for="(gitem, index) in storeLocationItems"
                          :key="index"
                          href="javascript:void(0);"
                          @click="goToSlide(index)"
                          :class="{ 'gg-active': index === activeIndex }"
                          >{{ gitem.figureCaption.title }}</a
                        >
                      </nav>
                    </div>
                    <swiper
                      class="g-swiper-container"
                      id="majorSwiper"
                      key="gSwiperContainer"
                    >
                      <swiper-slide
                        v-for="(item, slideIndex) in storeLocationItems"
                        :key="item.id"
                      >
                        <!-- 这里放置轮播内容 -->
                        <figure>
                          <div class="g-video-wrap-container">
                            <video
                              :src="item.figureVideoSrc"
                              :alt="item.figureCaption.title"
                              controls
                              muted
                              loop
                              controlsList="nodownload"
                              autoplay="index === activeIndex"
                            ></video>
                          </div>
                          <figcaption>
                            <h4>{{ item.figureCaption.title }}</h4>
                            <p
                              v-for="(text, tIndex) in item.figureCaption.desc"
                              :key="tIndex"
                            >
                              {{ text.dl }}{{ text.dd }}
                            </p>
                          </figcaption>
                        </figure>
                      </swiper-slide>
                    </swiper>
                    <div class="swiper-pagination" slot="pagination"></div>
                  </div>
                  <div
                    class="g-figure-list"
                    v-if="data.key === 'customReports'"
                  >
                    <figure
                      v-for="(intro, index) in productsIntros"
                      :key="index"
                      class="g-figure-img"
                    >
                      <img :src="intro.src" width="184" />
                      <figcaption>{{ intro.title }}</figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <!-- <div v-else> -->
              <!-- <div v-for="data in dataList" :key="data.key">
                <div class="subtitle" :style="{marginTop: data.key === 'index' ? '0.2rem' : '4rem'}">
                  <span class="relative">
                    <img class="icon absolute" :src="getImgUrl('subtitle_icon')" alt="" />
                    {{ data.title }}
                    <img class="icon1 absolute" :src="getImgUrl('subtitle_icon')" alt="" />
                  </span>
                </div>
                <Swiper :imgs="getSwiperImgs(`data_${data.key}`, 3)" :interval="3000" v-if="data.key ==='index'"></Swiper>
                <img :src="getImgUrl(`data_${data.key}`)" alt="" v-else />
              </div> -->
              <!-- </div> -->
            </div>
          </div>
          <div v-else-if="introduction.key === 'data'" class="data-capability">
            <div v-if="!isMobile">
              <div v-for="data in dataList" :key="data.key">
                <div
                  class="subtitle"
                  :class="{ 'mt-70': data.key === 'product' }"
                >
                  <span class="relative">{{ data.title }}</span>
                </div>
                <img :src="getImgUrl(`data_${data.key}`)" alt="" />
              </div>
            </div>
            <div v-else>
              <div v-for="data in dataList" :key="data.key">
                <div
                  class="subtitle"
                  :style="{
                    marginTop: data.key === 'index' ? '0.2rem' : '4rem',
                  }"
                >
                  <span class="relative">
                    <img
                      class="icon absolute"
                      :src="getImgUrl('subtitle_icon')"
                      alt=""
                    />
                    {{ data.title }}
                    <img
                      class="icon1 absolute"
                      :src="getImgUrl('subtitle_icon')"
                      alt=""
                    />
                  </span>
                </div>
                <customSwiper
                  :imgs="getSwiperImgs(`data_${data.key}`, 3)"
                  :interval="3000"
                  v-if="data.key === 'index'"
                ></customSwiper>
                <img :src="getImgUrl(`data_${data.key}`)" alt="" v-else />
              </div>
            </div>
          </div>
          <div
            v-else-if="introduction.key === 'industry'"
            class="industry-solutions"
          >
            <div
              :class="{
                'home-main': !isMobile,
                'display-flex': !isMobile,
              }"
            >
              <div class="left relative">
                <img
                  class="absolute"
                  :src="getImgUrl('industry_bg')"
                  alt=""
                  v-if="isMobile"
                />
                <p class="title relative">{{ industryObj.left.title }}</p>
                <div
                  :class="{
                    'display-flex': isMobile,
                    'flex-wrap-wrap': isMobile,
                  }"
                >
                  <div
                    v-for="(bm, bmIndex) in industryObj.left.content"
                    :key="'bm_' + bmIndex"
                    class="item relative"
                  >
                    <div class="item-left">
                      <img
                        class="circle"
                        :src="getImgUrl('industry_circle')"
                        alt=""
                      />
                    </div>
                    <div class="item-right">{{ bm }}</div>
                  </div>
                </div>
              </div>
              <div class="right">
                <div
                  v-for="(industry, industryIndex) in industryObj.right"
                  :key="'industry_' + industryIndex"
                  class="item relative"
                  :class="{ 'mb-25': !isMobile && industryIndex < 2 }"
                >
                  <img
                    class="left-icon absolute"
                    :src="getImgUrl('industry_icon')"
                    alt=""
                  />
                  <img
                    class="right-icon absolute"
                    :src="getImgUrl(`industry_${industry.key}`)"
                    alt=""
                  />
                  <p class="title mb-20">{{ industry.title }}</p>
                  <div class="content">{{ industry.content }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="introduction.key === 'case'" class="customer-cases">
            <div class="case-header" ref="caseHeader">
              <div
                v-for="(cusCase, caseIndex) in caseList"
                :key="cusCase.key"
                class="case-item"
                :class="{ active: caseIndex === caseCurIndex }"
                @click="onChangeCase(caseIndex)"
              >
                <img
                  class="case-img"
                  :src="
                    getImgUrl(
                      `${cusCase.key}${
                        caseIndex === caseCurIndex ? '_active' : ''
                      }_icon`
                    )
                  "
                  alt=""
                />
                <span
                  class="case-title"
                  :class="{ active: caseIndex === caseCurIndex }"
                >
                  {{ cusCase.title }}
                </span>
              </div>
            </div>
            <div class="case-content relative">
              <img
                class="case-img"
                :src="getImgUrl(`${caseCurContent.key}_bg`)"
                alt=""
              />
              <div class="right relative">
                <div class="right-content">
                  <p class="title">项目背景</p>
                  <div class="item mb-20">
                    <div class="item-left">
                      <div class="circle"></div>
                    </div>
                    <div class="item-right">
                      {{ caseCurContent.projectBackground }}
                    </div>
                  </div>
                  <p class="title">解决方案</p>
                  <div class="item" v-if="caseCurContent.solution?.title">
                    <div class="item-right">
                      {{ caseCurContent.solution.title }}
                    </div>
                  </div>
                  <div
                    v-for="(solution, solutionIndex) in caseCurContent.solution
                      .content"
                    :key="'solution_' + solutionIndex"
                    class="item"
                  >
                    <div class="item-left">
                      <div class="circle"></div>
                    </div>
                    <div class="item-right">{{ solution }}</div>
                  </div>
                  <p class="title mt-20">客户收益</p>
                  <div class="item">
                    <div class="item-left">
                      <div class="circle"></div>
                    </div>
                    <div class="item-right">
                      {{ caseCurContent.customerEarning }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="service-customer">
              <div class="subtitle">
                <span class="relative">
                  <img
                    class="icon absolute"
                    :src="getImgUrl('subtitle_icon')"
                    alt=""
                    v-if="isMobile"
                  />
                  服务客户
                  <img
                    class="icon1 absolute"
                    :src="getImgUrl('subtitle_icon')"
                    alt=""
                    v-if="isMobile"
                  />
                </span>
              </div>
              <div class="rolling-wrap relative">
                <img
                  class="mask1 absolute"
                  :src="getImgUrl('service_mask')"
                  alt=""
                  v-if="!isMobile"
                />
                <div class="rolling-img">
                  <img
                    class="rolling"
                    :src="getImgUrl('service_customer')"
                    alt=""
                  />
                  <img
                    class="rolling rolling2"
                    :src="getImgUrl('service_customer')"
                    alt=""
                  />
                </div>
                <img
                  class="mask2 absolute"
                  :src="getImgUrl('service_mask')"
                  alt=""
                  v-if="!isMobile"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="freeTrial">
        <div class="free-trial" v-if="!isMobile">
          <p class="trial-title">申请试用</p>
          <p class="trial-sub-title">
            留下您的联系方式，我们专业顾问会尽快与您联系
          </p>
          <div class="form">
            <div
              v-for="(form, formIndex) in formList"
              :key="'form_' + formIndex"
            >
              <div
                v-if="!form.isTextarea"
                class="form-item"
                :class="{
                  'mb-20': !showErr,
                  'mb-10': showErr,
                }"
              >
                <span class="required">*</span>
                <label>{{ form.placeholder }}</label>
                <input v-model="form.value" />
              </div>
              <Input
                v-else
                v-model="form.value"
                type="textarea"
                :placeholder="form.placeholder"
              />
              <div class="form-item-err mb-10 ml-20" v-show="showErr">
                {{ formError[form.key] || "" }}
              </div>
            </div>
          </div>
          <div data-agl-cvt="5" id="btn_submit" class="trial-btn" v-loading="submitLoading" @click="onSubmit">
            提交
          </div>
        </div>
      </div>
      <Footer :isMobile="isMobile"></Footer>
    </div>

    <!-- <div class="contact-panel1" v-if="!isMobile">
      <Tooltip placement="left" theme="light" class="tooltip">
        <div class="icon icon1"></div>
        <template #content>
          <div class="phone">
            <p class="text">咨询热线</p>
            <p class="phone-bumber">010-86463634</p>
          </div>
        </template>
      </Tooltip>
      <Tooltip placement="left" theme="light" class="tooltip">
        <div class="icon icon2"></div>
        <template #content>
          <div class="qrcode">
            <div
              v-for="(qrcode, index) in qrcodeList"
              :key="'qrcode_' + index"
              class="qrcode-item"
            >
              <img :class="'img' + index" :src="qrcode.url" alt="" />
              <div>{{ qrcode.text }}</div>
            </div>
          </div>
        </template>
      </Tooltip>
    </div> -->

    <div class="qrcode-wrap absolute" v-if="isShowQrcode">
      <div class="qrcode">
        <img :src="getImgUrl('qrcode')" alt="" />
        <div>千里目小助手</div>
      </div>
      <img
        class="close_btn"
        :src="getImgUrl('close_white')"
        alt=""
        @click="onShowQrcode(false)"
      />
    </div>

    <div class="nav-wrap absolute" v-if="isShowMobileNav">
      <div class="blank absolute" @click="onShowMobileNav(false)"></div>
      <div class="nav">
        <ul>
          <li v-for="introduction in introductionList" :key="introduction.key">
            <a
              :href="`#${introduction.key}`"
              :target="'_self'"
              @click="onSelectNav(introduction.key)"
            >
              {{ introduction.title }}
            </a>
          </li>
          <li>
            <a
              href="https://changjing.com.cn"
              :target="'_blank'"
              @click="onSelectNav"
            >
              亿景官网
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getDprImg } from "@/assets/javascript/utils.js";
import { SubmitFreeTrial } from "@/service";
import Header from "@/components/home/Header.vue";
import Footer from "@/components/home/Footer.vue";
import customSwiper from "@/components/home/Swiper.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  metaInfo: {
    title: "",
    meta: [
      {
        name: "keywords",
        content:
          "亿景知客,大数据,商业数据,分析报告,数据查询,数据分析,数据可视化,实时客流,动态数据,到访客流,进场分析,停留时长,客群画像,用户偏好,开店选址,开关店数据,客群消费力,客群来源地,客群消费地,客群聚集地,流失客去向,人口规模,新老客分析,小区配套,写字楼配套,医院配套,学校配套,交通配套,人均消费,客群复购率,竞品分析,4S店客研,售楼处客研,渗透率,对比分析,区域分析,商圈分析,商场分析,重合分析,业态分布,周边配套,人口热力图,全国数据,时空大数据,可达域查询,常驻人群,居住人群,工作人群,差旅人群,高收入人群,交通方式,产业洞察,门店洞察,商场定位,市场洞察,营销投放,品牌招商,投资拿地,进驻城市,商业决策,数字化,数字营销,智慧商业",
      },
      {
        name: "description",
        content:
          "一款专注于商业的全景数据分析可视化平台。提供门店客研、商场客研、区域客研、城市客研、开店选址、定制报告等全域多维数据查询分析服务，查到真正需要的数据，驱动业务发展和决策，助力企业发现商业机会。",
      },
    ],
  },
  components: {
    Header,
    Footer,
    customSwiper,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      banner: {
        title: "城市地图大数据平台，查到您真正需要的数据",
        desc: "门店客研  |  商场客研  |  区域客研  |  城市客研  |  选址模型  |  选址推荐位  |  定制报告",
      },
      showErr: false,
      activeIndex: 0,
      majorFuncActiveIndex: 0,
      submitLoading: false,
      swiperOption: {
        effect: "fade", // 设置为淡入淡出效果
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      majorFunctionItems: [
        {
          id: "b1",
          figureVideoSrc:
            "https://wd-zhike.oss-cn-beijing.aliyuncs.com/videozip/%E5%95%86%E5%9C%BA%E5%AE%A2%E7%A0%94.mp4",
          figureCaption: {
            title: "商场客研",
            desc: [
              {
                dl: "经营指标：",
                dd: "到访客流、停留时长、竞品到访、新老客分析、流失客分析等",
              },
              {
                dl: "地理属性：",
                dd: "客群来源地、客群消费地、周边渗透率、流失客去向等",
              },
              {
                dl: "客群画像：",
                dd: "收入消费、业态偏好、品牌偏好、商场偏好、交通方式等",
              },
              {
                dl: "精准营销：",
                dd: "流失客挽回、竞品营销、周边营销、会员营销等",
              },
            ],
            tags: [],
          },
        },
        {
          id: "b2",
          figureVideoSrc:
            "https://wd-zhike.oss-cn-beijing.aliyuncs.com/videozip/%E9%97%A8%E5%BA%97%E5%AE%A2%E7%A0%94.mp4",
          figureCaption: {
            title: "门店客研",
            desc: [
              {
                dl: "经营指标：",
                dd: "客流趋势、停留时长、竞品到访、新老客分析、流失客分析等",
              },
              {
                dl: "地理属性：",
                dd: "客群来源地、常驻地距离分布、外地客群占比、流失客去向等",
              },
              {
                dl: "客群画像：",
                dd: "收入消费、业态偏好、餐饮偏好、商场偏好、交通方式等",
              },
              {
                dl: "精准营销：",
                dd: "门店经营评分、店铺位置得分、选址打分表、选址推荐位等",
              },
            ],
            tags: ["售楼处", "4S店", "餐饮零售", "休闲娱乐", "亲子服务"],
          },
        },
        {
          id: "b3",
          figureVideoSrc:
            "https://wd-zhike.oss-cn-beijing.aliyuncs.com/videozip/AOI%E5%AE%A2%E7%A0%94.mp4",
          figureCaption: {
            title: "AOI客研",
            desc: [
              { dl: "经营指标：", dd: "人群数量统计" },
              { dl: "地理属性：", dd: "消费地统计" },
              {
                dl: "客群画像：",
                dd: "收入消费、业态偏好、品牌偏好、商场偏好、交通方式等",
              },
            ],
            tags: ["写字楼", "住宅区"],
          },
        },
        {
          id: "b4",
          figureVideoSrc:
            "https://wd-zhike.oss-cn-beijing.aliyuncs.com/videozip/%E5%8C%BA%E5%9F%9F%E5%AE%A2%E7%A0%94.mp4",
          figureCaption: {
            title: "区域客研",
            desc: [
              {
                dl: "人口分布：",
                dd: "常驻人群、居住人群、工作人群、年轻群体、高收入人群、迁入迁出人群、客群画像、热力图等",
              },
              {
                dl: "POI分布：",
                dd: "商业、住宅、酒店、医院、学校、地铁、车站、业态、品牌等",
              },
              {
                dl: "聚客点分析：",
                dd: "消费类型、时间段、日期类型、消费业态、消费品牌等",
              },
            ],
            tags: [],
          },
        },
        {
          id: "b5",
          figureVideoSrc:
            "https://wd-zhike.oss-cn-beijing.aliyuncs.com/videozip/%E5%9F%8E%E5%B8%82%E5%AE%A2%E7%A0%94.mp4",
          figureCaption: {
            title: "城市客研",
            desc: [
              {
                dl: "城市设施：",
                dd: "商场、交通、住宅、写字楼、学校、餐饮、酒店、购物等",
              },
              {
                dl: "人口信息：",
                dd: "人口数量、基础画像、收入消费、职业分布、人群分布等",
              },
              {
                dl: "城市交通：",
                dd: "客流量、通勤数据、区内外职住分离、汽车品牌分布等",
              },
              {
                dl: "行业发展：",
                dd: "餐饮、购物、酒店、新车、新房、旅游、亲子、娱乐等",
              },
              { dl: "人口流动：", dd: "常驻人口、假期人口、差旅人口等" },
              { dl: "城市热力图：", dd: "工作日、休息日、0-24小时等" },
            ],
            tags: [],
          },
        },
      ],
      storeLocationItems: [
        {
          id: "a1",
          figureVideoSrc:
            "https://wd-zhike.oss-cn-beijing.aliyuncs.com/videozip/%E5%93%81%E7%89%8C%E6%B4%9E%E5%AF%9F.mp4",
          figureCaption: {
            title: "品牌洞察",
            desc: [
              {
                dl: "品牌库：",
                dd: "全国品牌列表、省市筛选、业态筛选、阶段筛选等",
              },
              {
                dl: "品牌详情：",
                dd: "客流趋势、客群画像、门店分布、拓店趋势、复购率分布、竞争品牌、区域排名、品牌评价等",
              },
              {
                dl: "品牌排名：",
                dd: "门店排名、客流量排名、门店增减排名、综合得分排名等",
              },
            ],
          },
        },
        {
          id: "a2",
          figureVideoSrc:
            "https://wd-zhike.oss-cn-beijing.aliyuncs.com/videozip/%E5%95%86%E5%9C%BA%E6%B4%9E%E5%AF%9F.mp4",
          figureCaption: {
            title: "商场洞察",
            desc: [
              { dl: "商场库：", dd: "全国商场列表、省市区筛选等" },
              {
                dl: "商场详情：",
                dd: "新老客户比例、入驻商户类型、门店复购率、门店生存率、交通配套、商户业态、适合品牌、商场评价等",
              },
              {
                dl: "商场排名：",
                dd: "门店排名、客流量排名、复购率排名、不同业态人次排名等",
              },
            ],
          },
        },
        {
          id: "a3",
          figureVideoSrc:
            "https://wd-zhike.oss-cn-beijing.aliyuncs.com/videozip/%E9%80%89%E5%9D%80%E6%A8%A1%E5%9E%8B.mp4",
          figureCaption: {
            title: "选址模型",
            desc: [
              {
                dl: "模型评估：",
                dd: "业态类型、店铺类型、城市级别、目标月客流量、目标复购率、所选位置客流量、所选位置复购率、竞品数、回头客、生存率、存活周期、交通便利度、同业门店客流等",
              },
              {
                dl: "模型筛选：",
                dd: "店铺类型、目标月客流、业态类型、城市级别等",
              },
            ],
          },
        },
        {
          id: "a4",
          figureVideoSrc:
            "https://wd-zhike.oss-cn-beijing.aliyuncs.com/videozip/%E9%80%89%E5%9D%80%E5%B7%A5%E5%85%B7.mp4",
          figureCaption: {
            title: "选址工具",
            desc: [
              { dl: "圈选位置：", dd: "省市区、人群画像、聚客点、热力图等" },
              {
                dl: "位置画像：",
                dd: "人群画像、设施配套、业态配套、品牌配套、待租店铺等",
              },
              {
                dl: "选址推荐位：",
                dd: "选址推荐位列表和详情（目标月客流量、目标复购率、竞品数、回头客、生存率、存活周期、交通便利度、同业门店客流等）",
              },
            ],
          },
        },
      ],
      productsIntros: [
        {
          src: "https://xz.changjing.com.cn/images/function_5.png",
          title: "门店分析报告",
        },
        {
          src: "https://xz.changjing.com.cn/images/function_3.png",
          title: "品牌分析报告",
        },
        {
          src: "https://xz.changjing.com.cn/images/function_7.png",
          title: "商场分析报告",
        },
        {
          src: "https://xz.changjing.com.cn/images/function_4.png",
          title: "区域分析报告",
        },
        {
          src: "https://xz.changjing.com.cn/images/function_1.png",
          title: "选址分析报告",
        },
        {
          src: "https://xz.changjing.com.cn/images/function_6.png",
          title: "4S店营销分析报告",
        },
        {
          src: "https://xz.changjing.com.cn/images/function_2.png",
          title: "售楼处营销分析报告",
        },
        {
          src: "https://xz.changjing.com.cn/images/function_8.png",
          title: "普通营销分析报告",
        },
      ],
      formList: [
        {
          key: "name",
          value: "",
          placeholder: "姓名",
          isRequired: true,
          invalid: false,
        },
        {
          key: "phone",
          value: "",
          placeholder: "手机号码",
          isRequired: true,
          invalid: false,
        },
        {
          key: "industry",
          value: "",
          placeholder: "行业",
          isRequired: true,
          invalid: false,
        },
        {
          key: "message",
          value: "",
          placeholder: "留言（使用目的、使用部门、预算区间...）",
          isTextarea: true,
        },
      ],
      totalList: [
        {
          key: "user",
          title: "覆盖用户",
          num: 9.5,
          unit: "亿+",
        },
        {
          key: "geom",
          title: "地理画像",
          num: 1.5,
          unit: "亿+",
        },
        {
          key: "store",
          title: "品牌门店",
          num: 800,
          unit: "万+",
        },
        {
          key: "brand",
          title: "品牌",
          num: 15,
          unit: "万+",
        },
        {
          key: "city",
          title: "城市",
          num: 367,
          unit: "",
        },
      ],
      productList: [
        {
          key: "brand",
          title: "品牌商业数据洞察",
          desc: [
            "企业数据：所属公司、行业类型、工商资质，经营模式等。",
            "线下数据：门店分布、周边客群、到访分析、客户画像、门店销量等。",
            "线上数据：投放趋势、媒体偏好、投放效果、以及视频归因分析等。",
          ],
          tags: [
            "品牌商业报告",
            "开店选址评分",
            "竞品分析",
            "线上营销洞察",
            "市场调研",
            "客群画像",
          ],
          height: "495px",
        },
        {
          key: "store",
          title: "品牌门店",
          desc: [
            "区域信息：覆盖人群、公建配套、业态分布等。",
            "客群到访：到访分析、停留时长、客户画像等。",
            "品类销量：热销品类、销量趋势、消费客群来源等。",
          ],
          tags: [
            "门店周边配套",
            "周边竞争评估",
            "门店到访人群",
            "消费人群来源",
            "门店销量分析",
            "周边客群分析",
          ],
          height: "468px",
        },
        {
          key: "location",
          title: "区域位置",
          desc: [
            "配套信息：写字楼、住宅、学校、公司、商业、医院、交通的分布，以及平均房价等。",
            "客群特征：人口规模、人群基础信息、人群特征画像等。",
            "业态分布：餐饮、零售、生活服务、宾馆酒店、医疗保健、体育休闲、金融银行等分布。",
          ],
          tags: [
            "小区分布",
            "写字楼分布",
            "人口结构洞察",
            "商业业态分布",
            "常驻人群品牌偏好",
            "交通状况",
          ],
          height: "484px",
        },
        {
          key: "mall",
          title: "商场/商圈洞察",
          desc: [
            "商圈：清晰呈现商圈内，商圈级别、商业项目、占地面积、入驻品牌、客群画像、 客流分析、人群线下行为轨迹，周边基础设施等数据。",
            "商场：商场的基础信息，也可以深度洞察并分析商场区位、业态购成、商户分布、周边客群分析、客流分析等数据。",
          ],
          tags: [
            "商圈到访趋势",
            "周边客群分析",
            "区域配套洞察",
            "聚客类型洞察",
          ],
          height: "482px",
        },
        {
          key: "city",
          title: "城市洞察",
          desc: [
            "综合经济：GDP、人均可支配收入、人均消费支出、消费品零售总额等。",
            "商业数据：城市商业成熟度、商圈/购物中心分布、商业中心面积，人均占有量等。",
            "城市交通：公交线路、地铁、地铁站、火车站、机场基础信息及地图点位分布等。",
          ],
          tags: ["综合经济报告", "城市商业项目分布", "城市交通分布洞察"],
          height: "479px",
        },
      ],
      newProductList: [
        {
          key: "majorFunction",
          title: "主要功能",
        },
        {
          key: "storeLocation",
          title: "开店选址",
        },
        {
          key: "customReports",
          title: "定制报告",
        },
      ],
      dataList: [
        {
          key: "index",
          title: "指标维度",
        },
        {
          key: "product",
          title: "产品结构",
        },
      ],
      industryObj: {
        left: {
          title: "品牌营销",
          content: [
            "触达客群特征画像",
            "门店数字营销地图",
            "目标选址智能评级",
            "周边商业生态指数",
            "营销广告投流数据",
            "挖掘市场潜在机会",
            "竞品/店监测洞察",
          ],
        },
        right: [
          {
            key: "restaurant",
            title: "餐饮连锁",
            content:
              "全面了解区域内业态分布与客流规模，判断区域内消费者消费偏好, 挖掘位置数据商业价值，提供从“选址-开店-运营-营销”的全链路服务。",
          },
          {
            key: "house",
            title: "商业地产",
            content:
              "通过商业规划分析、城市宏观数据、地理位置信息等能力辅助地产企业将多业态、多元化用户和场景信息数字化，在土地商务投拓，用地分析上打造配套完善、提供了辅助决策支持。",
          },
          {
            key: "retail",
            title: "品牌零售",
            content:
              "提供区域竞争监测能力，及时了解门店周边客流趋势，聚焦高频交易和高覆盖用户的连锁零售场景。服务内容包括：门店选址，商址评估，客群行为洞察，竞品分析，精准营销等。",
          },
          {
            key: "bank",
            title: "银行金融",
            content:
              "通过网点业务数据化，为银行等金融机构建立完善的数据资产地图，通过数据模型算法将银行的产品服务与场景深度融合，解决差异化区域经营难题与网点布局优化等问题。",
          },
        ],
      },
      caseList: [
        {
          key: "case_restaurant",
          title: "餐饮行业",
          projectBackground:
            "客户公司为某餐饮连锁品牌，目前已上市进入扩张阶段，希望以某一线城市为核心，预估市场机会，拓展更多连锁加盟，进行开店选址分析，构建门店智能选址分析模型，为品牌业务扩张、门店选址、提供数据支撑，辅助战略及业务决策。",
          solution: {
            title: "门店洞察+地理建模+数据分析，实现智能门店选址模型：",
            content: [
              "深度洞察现有门店经营效能影响因素，智能化评估门店投资回报水平和经营成长性。",
              "通过门店现有位置及历史经营水平，进行机器学习建模，并验证模型的预测情况。",
              "提炼影响现有门店经营水平的选址关键指标，辅助新开门店选址落地可行性。",
              "分析当前地区用户的习惯、常驻和流动人员分布、客群画像、周边竞争态势、交通便利性等数据， 判断某个地点是否适合开店，并给出关于在该地店的评分。",
            ],
          },
          customerEarning:
            "为客户在某一线城市地区选址拓店，提供了数据支持和智能化的决策依据。比传统的依靠调研和专家经验的方式大幅提高了决策效率和准确率。通过分析当地行业市场，人群消费偏好等数据，为品牌业务扩张提供市场调研决策数据。",
        },
        {
          key: "case_retail",
          title: "零售行业",
          projectBackground:
            "客户公司为某零售连锁品牌，该品牌在28个省市已发展近1133家门店，门店陷入增长困境，需要对门店评级分类，挖掘业务影响关键因子，制定策略实现差异化经营，依据市场归因实现销量预测，需求合作渠道拓展、选品优化提升经营效率等。",
          solution: {
            content: [
              "依赖大数据引擎对多门店数据进行汇总，实现时空大数据的统一视图的管理，解决了数据汇总和信息化管理的问题。",
              "利用地理商业智能引擎的地理编码模块实现快捷的营业网点数据上图，利用平台便捷的构建业务作战地图，实现一站式品牌市场洞察。",
              "综合门店与商圈数据、宏观经济数据，测算不同省份、城市的资源投放优先级与单个门店的指数，为销售策略制定提供参考。",
            ],
          },
          customerEarning:
            "有效帮助品牌客户总计1133门店，进行数字化智能化运营。在门店评级、旺店评估、销售预测、归因分析、运营优化和营销优化等方面，提供线下大数据和智能预测结果辅助决策，有效指导门店运营优化。",
        },
        {
          key: "case_house",
          title: "地产行业",
          projectBackground:
            "客户公司为某头部地产品牌，业务痛点是：缺少有效的投拓拿地分析，不能高效将信息数据整合；缺少政府对于土地开发的规划，以及数据支持市场投拓业务。",
          solution: {
            content: [
              "依赖地理商业智能引擎，支持智能地产行业投拓数据分析，在土地商务投拓，用地分析上提供了辅助决策支持。",
              "具体实现了规划数据建设，业务数据归档，目标地块智能查询，精准营销专题地图，利用统一时空计算模型实现了地块智能评分，利用高效的可视化渲染能力实现了友好的客户交互，有效的提升了地产投拓决策效率。",
              "根据城市宏观数据进行城市建设规划分析和区域配套，人口画像，消费便好等数据整合。",
            ],
          },
          customerEarning:
            "补全了辅助决策支持的数据资源。提供的周边情况信息以及地块智能评分提升了决策效率。高性能可视化可以更直观地对于整个市场的宏观布局提供鹰眼透视能力。",
        },
      ],
      qrcodeList: [
        {
          url: "/images/qrcode.png",
          text: "千里目小助手",
        },
        {
          url: "/images/yj_qrcode.png",
          text: "关注公众号",
        },
        {
          url: "/images/home/qrcode.png",
          text: "千里目小程序",
        },
      ],
      regPhone: /^1[3-9]\d{9}$/,
      caseCurIndex: 0,
      caseCurContent: {},
      scrollview: null,
      headerFixed: false,
      activeNav: "",
      isShowQrcode: false,
      isShowMobileNav: false,
      scrollTop: null,
      caseHeaderItemsOffset: [],
      caseHeaderOffsetLeft: 0,
      caseHeaderOffsetWidth: 0,
    };
  },
  computed: {
    ...mapState(["introductionList"]),
    formError() {
      let err = {};
      let errList = {
        name: "姓名不能为空",
        phone: "手机号码不能为空",
        phone1: "手机号码格式错误",
        industry: "行业不能为空",
      };
      this.formList.forEach((item, index) => {
        if (index === this.formList.length - 1) return;
        if (item.key === "phone") {
          if (!item.value) {
            err[item.key] = errList[item.key];
            return;
          }
          if (!this.regPhone.test(item.value)) {
            err[item.key] = errList[`${item.key}1`];
          }
          return;
        }
        if (!item.value) {
          err[item.key] = errList[item.key];
        }
      });
      return err;
    },
    isMobile() {
      return this.$global.isMobile;
    },
  },
  methods: {
    getImgUrl(path) {
      return this.isMobile
        ? getDprImg(`/images/landingPage/mobile/${path}.png`)
        : `/images/landingPage/${path}.png`;
    },
    getSwiperImgs(type, num) {
      let imgs = [];
      for (let i = 1; i <= num; i++) {
        imgs.push(this.getImgUrl(`${type}${i}`));
      }
      return imgs;
    },
    majorGoToSlide(index) {
      this.majorFuncActiveIndex = index;
      const majorFuncSwiper = document.getElementById("majorFuncSwiper");
      majorFuncSwiper.swiper.slideTo(this.majorFuncActiveIndex);
    },
    goToSlide(index) {
      this.activeIndex = index;
      const majorSwiper = document.getElementById("majorSwiper");
      majorSwiper.swiper.slideTo(this.activeIndex);
    },
    onSelectNav(name) {
      if (this.isMobile) {
        this.isShowMobileNav = false;
      } else {
        document.getElementById(name) &&
          document.getElementById(name).scrollIntoView({ behavior: "smooth" });
      }
    },
    onShowQrcode(val) {
      this.isShowQrcode = val;
    },
    onShowMobileNav(val) {
      this.isShowMobileNav = val;
    },
    onChangeCase(index) {
      this.caseCurIndex = index;
      this.caseCurContent = this.caseList[this.caseCurIndex];
      this.scrollDom(index);
    },
    toIndex() {
      if (this.isMobile) {
        location.href = `${location.origin}/bridge.html`;
      } else {
        const targetElement = document.getElementById("freeTrial");
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    async onSubmit() {
      if (Object.keys(this.formError).length) {
        this.showErr = true;
        return;
      }
      this.showErr = false;
      this.submitLoading = true;
      let params = {};
      this.formList.forEach((item) => {
        params[item.key] = item.value;
      });
      params.alias = "知客";

      let url = window.location.href;
      let bdVid = "";
      if (url) {
        let urlArr = url.split("?");
        if (urlArr && urlArr.length > 1) {
          let paramsArr = urlArr[1].split("&");
          let paramsObj = {};
          paramsArr.forEach((item) => {
            let itemArr = item.split("=");
            paramsObj[itemArr[0]] = itemArr[1];
          });
          bdVid = paramsObj.bd_vid || "";
        }
      }
      params.bd_vid = bdVid; // 百度推广
      fetch("https://zk.changjing.com.cn/api/apply/hotmap", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && data.code === 0) {
            if (!this.isMobile) {
              this.submitLoading = false;
              this.$Message.success({
                content: "提交成功",
                top: 100,
                duration: 5,
                closable: true,
                background: true,
              });
              setTimeout(() => {
                window._agl && window._agl.push(['track', ['success', {t: 3}]])
                this.formList.map((item) => (item.value = ""));
              }, 200);
            }
          }
        })
        .catch((error) => {
          this.submitLoading = false;
          console.error("POST请求失败:", error);
        });
    },
    watchScroll() {
      let scrollTop = this.$refs.scrollview.scrollTop;
      this.headerFixed = this.isMobile
        ? scrollTop / this.$global.fontSize > 3
        : scrollTop > 100;

      let total = 0;
      let heightList = [];
      let bannerHeight = document.getElementById("banner").offsetHeight;
      heightList.push(bannerHeight);
      let introductionHeightList =
        document.getElementsByClassName("introduction");
      for (let i = 0; i < introductionHeightList.length; i++) {
        heightList.push(introductionHeightList[i].offsetHeight);
      }
      heightList = heightList.map((ele, index) => {
        total += ele;
        return index === heightList.length - 1 ? total - 82 : total - 2; // 防止锚点定位出现偏差
      });
      let moduleName = this.introductionList.map((item) => item.key);
      for (let i = 0; i < heightList.length; i++) {
        if (scrollTop > heightList[i] && scrollTop < heightList[i + 1]) {
          this.activeNav = moduleName[i];
        } else if (
          scrollTop <= heightList[0] ||
          scrollTop > heightList[heightList.length - 1]
        ) {
          this.activeNav = "";
        }
      }
    },
    initCaseScrollData() {
      let caseHeader = document.getElementsByClassName("case-header");
      this.caseHeaderOffsetLeft = caseHeader?.[0].offsetLeft;
      this.caseHeaderOffsetWidth = caseHeader?.[0].offsetWidth;
      let caseHeaderItems = document.getElementsByClassName("case-item");
      this.caseHeaderItemsOffset = [];
      for (let i = 0; i < caseHeaderItems.length; i++) {
        let item = caseHeaderItems[i];
        this.caseHeaderItemsOffset.push({
          width: item.offsetWidth,
          left: item.offsetLeft,
        });
      }
    },
    // 切换tab时，tab滑动到中间位置
    scrollDom(index) {
      if ((index !== 0 && !index) || !this.caseHeaderItemsOffset[index]) {
        return;
      }
      const offsetLeft =
        this.caseHeaderItemsOffset[index].left - this.caseHeaderOffsetLeft;
      const scrollLeft =
        offsetLeft -
        (this.caseHeaderOffsetWidth - this.caseHeaderItemsOffset[index].width) /
          2;
      let scrollToLeft = scrollLeft < 0 ? 0 : scrollLeft;
      let ele = this.$refs.caseHeader[0];
      ele.scrollTo({
        left: scrollToLeft,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.caseCurContent = this.caseList[this.caseCurIndex];
    this.scrollview = this.$refs.scrollview;
    if (this.scrollview) {
      this.scrollview.addEventListener("scroll", this.watchScroll, true);
    }

    if (this.isMobile) {
      this.$nextTick(() => {
        this.initCaseScrollData();
      });
    }
  },
};
</script>

<style lang="scss">
@import "~style/landing_page.scss";
.ivu-tooltip-inner {
  max-width: 300px;
}
.gg-active {
  color: #3519fb !important;
  border-bottom: 4px solid #3519fb;
}
.free-trial {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 531px !important;
  background: url("/images/free_trial.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  color: #ffffff;
  .trial-title {
    font-size: 36px;
    font-weight: 600;
    margin-top: 80px;
    color: #ffffff;
  }
  .trial-sub-title {
    font-size: 16px;
    margin-top: 12px;
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 800px;
    margin-top: 50px;
    .form-item {
      display: flex;
      align-items: center;
      width: 330px;
      height: 46px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 3px;
      .required {
        margin: auto 0;
        color: #ff0000;
        margin: 0 10px 0 20px;
      }
      label {
        max-width: 150px;
        color: #999999;
        font-size: 14px;
        margin-right: 10px;
      }
      input {
        height: 100%;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
    textarea.ivu-input {
      width: 330px;
      height: 46px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 3px;
      padding-left: 20px !important;
    }
    .form-item-err {
      font-size: 10px;
      color: #f81b1b;
    }
  }
  .trial-btn {
    width: 170px;
    height: 40px;
    background: #3278ff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-top: 40px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
