<template>
  <div class="footer">
    <div class="content">
      <div class="logo">
        <a
          href="https://changjing.com.cn"
          target="_blank"
        >
          <img src="/images/yj_logo_blue.png" alt />
        </a>
        <p class="phone" v-if="!isMobile">010-86463634</p>
        <div class="qr-code" v-if="qrcode && !isMobile">
          <img :src="qrcode" alt />
          <p>扫描二维码关注公众号</p>
        </div>
      </div>
      <ul class="foot-nav">
        <li v-for="(nav, i) in footNavList" :key="'foot_nav_' + i">
          <a
            class="foot-nav-cate"
            :href="`https://changjing.com.cn${nav.path}`"
            target="_blank"
            v-if="nav.path"
            >{{ nav.name }}</a
          >
          <a class="foot-nav-cate" v-else>{{ nav.name }}</a>
          <ul class="foot-nav-children">
            <li
              v-for="(item, index) in nav.children"
              :key="'foot_nav_' + i + '_' + index"
            >
              <a
                :href="item.path"
                target="_blank"
                v-if="item.path.indexOf('http') > -1"
                >{{ item.name }}</a
              >
              <a
                :href="`https://changjing.com.cn${item.path}`"
                target="_blank"
                v-else
                >{{ item.name }}</a
              >
            </li>
          </ul>
        </li>
      </ul>
      <div class="contact-wrap" v-if="isMobile">
        <img class="qrcode" :src="qrcode" alt="" />
        <p>扫描二维码关注亿景智联公众号</p>
        <p>010-86463634</p>
      </div>
      <div class="copyright" v-if="isMobile">
        <p>
          Copyright ©2020 changjing.com.cn Inc. All rights reserved | 
          <a target="_blank" href="https://beian.miit.gov.cn/"
            >苏ICP备2023018419号-1
          </a>
           | 亿景智联（苏州）科技有限公司 | 010-86463634(客服经理)
        </p>
      </div>
      <div class="copyright" v-else>
        <p>
          Copyright ©2020 changjing.com.cn Inc. All rights reserved
        </p>
        <p class="mt-10">
          <a target="_blank" href="https://beian.miit.gov.cn/"
            >苏ICP备2023018419号-1
          </a>
        </p>
        <p class="mt-10">
          亿景智联（苏州）科技有限公司 | 
          010-86463634(客服经理)
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { checkHash } from "@/assets/javascript/utils";
export default {
  props: ["isMobile"],
  data() {
    return {
      footNavList: [
        {
          name: "产品服务",
          path: "/products",
          children: [],
        },
        {
          name: "解决方案",
          children: [
            { name: "行业解决方案", path: "/solution/industry" },
            { name: "应用场景方案", path: "/solution/usercase" },
          ],
        },
        {
          name: "客户案例",
          path: "/client-case",
          children: [
            { name: "零售行业", path: "/client-case#retail" },
            { name: "地产行业", path: "/client-case#real_estate" },
            { name: "广告传媒", path: "/client-case#advertising_media" },
            { name: "金融保险", path: "/client-case#financial_insurance" },
            { name: "交通出行", path: "/client-case#transportation" },
            { name: "城市和政府", path: "/client-case#cities_government" },
            { name: "物流快递", path: "/client-case#express" },
          ],
        },
        {
          name: "关于我们",
          path: "/about-us",
          children: [
            { name: "公司介绍", path: "/about-us#introduce" },
            { name: "战略投资方", path: "/about-us#partner" },
            { name: "联系我们", path: "/about-us#contact" },
          ],
        },
        {
          name: "友情链接",
          children: [
            { name: "秒影工场", path: "https://www.clipworks.com/" },
            // { name: "地图无忧", path: "https://www.dituwuyou.com" },
          ],
        },
      ],
      productList: [
        {
          name: "亿景智图",
          path: "https://zt.changjing.com.cn",
          icon: "/static/images/products/product_dtwy.png",
          bg: "/static/images/products/product_dtwy_bg1.png",
          info:
            "基于时空大数据的位置智能服务平台，是创新型企业级地理信息SaaS平台",
        },
        {
          name: "亿景排班",
          path: "https://pb.changjing.com.cn/",
          icon: "/static/images/products/product_wcpb.png",
          bg: "/static/images/products/product_wcpb_bg1.png",
          info:
            "帮助企业提升客户拜访的效率，使业务人员可以服务更多的客户，扩大业务规模，赚取更多利润",
        },
        {
          name: "亿景知客",
          path: "https://zhike.changjing.com.cn/home",
          icon: "/static/images/products/product_wczk.png",
          bg: "/static/images/products/product_wczk_bg1.png",
          info:
            "为企业进行客群分析，深度刻画用户画像，帮助企业定位目标客群，驱动企业高效实现客户增长，制定产品的销售策略。",
        },
        {
          name: "亿景选址",
          path: "https://xz.changjing.com.cn/",
          icon: "/static/images/products/product_wcyy.png",
          bg: "/static/images/products/product_wcyy_bg1.png",
          info:
            "通过全面的客群和环境分析来解决店铺开在哪里的问题，减低选址的成本，提升店铺选址的成功率。",
        },
        {
          proName: "fd",
          name: "亿景分单",
          path: "https://fd.changjing.com.cn/",
          icon: "/static/images/products/product_yjfd.png",
          bg: "/static/images/products/product_yjfd_bg1.png",
          info:
            "利用地理信息编码技术和范式星图丰富的地址库AI智能匹配技术帮助物流企业精准解析客户地址，快速分拣物流订单",
        },
        {
          name: "城市洞察",
          path: "https://dc.changjing.com.cn/",
          icon: "/static/images/products/product_csdc.png",
          bg: "/static/images/products/product_csdc_bg1.png",
          info:
            "为城市管理者及各行各业提供大数据分析服务，对城市各领域进行多维度洞察与分析，实现产业洞察、智能规划、精准招商等功能",
        },
        {
          name: "千里目",
          path: "https://www.qianlimu.com/home",
          icon: "/static/images/products/product_qlm.png",
          bg: "/static/images/products/product_qlm_bg1.png",
          info:
            "支持品牌、门店、位置、商场、商圈、城市、客群等多维全域商业数据查询并分析，以数据驱动商业发展和决策，助力企业发现商业机会",
        },
        {
          name: "客流通",
          path: "https://klt.changjing.com.cn/home",
          icon: "/static/images/products/product_hotmap.png",
          bg: "/static/images/products/product_hotmap_bg1.png",
          info:
            "专注于商业的全景数据分析可视化平台。利用时空大数据助力商业项目分析与价值提升，驱动业务高效精准决策",
        }
      ],
      qrcode: "/images/yj_qrcode.png",
    };
  },
  methods: {
    handleClickLink(url) {
      let path = this.$route.path;
      let hash = this.$route.hash;
      let linkPath = url;
      let linkHash = "";
      let hashIndex = url.indexOf("#");
      if (hashIndex !== -1) {
        linkPath = url.substr(0, hashIndex);
        linkHash = url.substr(hashIndex);
      }
      if (path === linkPath && hash === linkHash) {
        checkHash(this);
      }
    },
  },
  created() {
    this.footNavList[0].children = this.productList;
  },
};
</script>
<style lang="scss">
@import "~style/config.scss";

.footer {
  width: 100%;
  height: 800px;
  background: #f9fbff;
  overflow: hidden;
  font-size: 14px;
  padding: 120px 0 94px;
  .content {
    @include wrap_width;
    overflow: hidden;
    position: relative;
    height: 100%;

    .logo {
      width: 210px;
      float: left;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .foot-nav {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: absolute;
      right: 0;
      top: 0;
      width: 800px;
      font-size: 16px;
      a {
        font-size: 16px;
      }
      @media screen and (max-width: 1440px) {
        a {
          font-size: 14px;
        }
      }

      & > li {
        .foot-nav-cate {
          color: #333;
          font-weight: 600;
        }

        .foot-nav-children {
          margin-top: 50px;

          li {
            line-height: 42px;

            a {
              color: rgba(17, 17, 17, 0.5);
              transition: 0.3s;
              &:hover {
                color: #1e4b9f;
              }
            }
          }
        }
      }
    }

    .copyright {
      position: absolute;
      right: 20px;
      bottom: 0;
      color: rgba(17, 17, 17, 0.5);
      p {
        text-align: right;
        a {
          color: rgba(17, 17, 17, 0.5);
        }
      }
    }

    .phone {
      font-size: 20px;
      font-weight: 800;
      color: #333333;
      margin-top: 62px;
      line-height: 28px;
    }

    .qr-code {
      margin: 0 auto;
      color: rgba(51, 51, 51, 0.5);
      font-size: 14px;
      margin-top: 10px;

      img {
        width: 162px;
        height: 162px;
      }
    }
  }
  .mt-10 {
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    height: auto;
    background: #f9fbff;
    padding: 6rem 0;
    .content {
      height: 100%;
      position: relative;
      overflow: hidden;

      .logo {
        width: 100%;
        float: left;
        text-align: left;
        img {
          width: 10rem;
        }
      }

      .foot-nav {
        width: 100%;
        float: left;
        overflow: hidden;
        display: block;
        position: static;
        margin-top: 4rem;

        & > li {
          float: left;
          width: 50% !important;
          padding-left: 1rem;
          margin-bottom: 4rem;
          a {
            font-size: 1.4rem;
          }
          &:nth-of-type(2n + 1) {
            clear: left;
          }
          .foot-nav-cate {
            color: #333;
            font-weight: 600;
          }

          .foot-nav-children {
            margin-top: 2.8rem;
          }
        }
      }

      .contact-wrap {
        text-align: center;
        border-bottom: 1px solid rgba(238, 238, 238, 1);
        padding-bottom: 4rem;
        .qrcode {
          width: 22rem;
        }
        p {
          font-size: 1.4rem;
          color: rgba(17, 17, 17, 0.5);
          line-height: 2rem;
          margin-top: 0.9rem;
        }
      }

      .copyright {
        position: static;
        color: rgba(17, 17, 17, 0.5);
        font-size: 1.4rem;
        margin-top: 4rem;
        line-height: 1.7rem;
        p {
          text-align: left;
          a {
            color: rgba(17, 17, 17, 0.5);
          }
        }
      }
    }
  }
}
</style>
