<template>
  <div
    class="header"
    :class="{
      bg: headerFixed && !isMobile,
      bg1: headerFixed && isMobile,
      'mobile-height': isMobile,
    }"
  >
    <div class="content" v-if="!isMobile">
      <img src="/images/landingPage/logo_white.png" alt="" />
      <div
        v-for="introduction in introductionList"
        :key="introduction.key"
        class="nav"
        :class="{ cur: curNav === introduction.key }"
        @click="onClick(introduction.key)"
      >
        <span>{{ introduction.title }}</span>
      </div>
      <div class="nav" @click="onClick('yj-offical-website')">亿景官网</div>
      <div class="g-phone">
        <img class="phone" src="/images/head_phone.png" alt="电话" width="20" />
        <span class="phone-number">010-86463634</span>
      </div>
      <div class="g-toolbar">
        <a id="login"
          href="https://workspace.zk.changjing.com.cn/"
          target="_blank"
          class="login"
          >登录</a
        >
        <a id="free-try" href="#freeTrial" class="free-try">免费试用</a>
      </div>
    </div>
    <div class="mobile-content" v-else>
      <img class="logo" :src="getImgUrl('logo')" alt="" />
      <div class="icons">
        <img
          class="icon"
          :src="getImgUrl('qrcode_icon')"
          alt=""
          @click="onClick('qrcode')"
        />
        <a href="tel:010-86463634" class="icon">
          <img :src="getImgUrl('phone_icon')" alt="" />
        </a>
        <img
          class="icon"
          :src="getImgUrl('nav_icon')"
          alt=""
          @click="onClick('nav')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getDprImg } from "@/assets/javascript/utils.js";
export default {
  props: ["headerFixed", "activeNav", "isMobile"],
  data() {
    return {
      curNav: "",
    };
  },
  computed: {
    ...mapState(["introductionList"]),
  },
  watch: {
    activeNav: {
      handler(val) {
        this.curNav = val;
      },
      immediate: true,
    },
  },
  methods: {
    getImgUrl(path) {
      if (this.isMobile && this.headerFixed) {
        path = path.concat("_blue");
      }
      return this.isMobile
        ? getDprImg(`/images/landingPage/mobile/${path}.png`)
        : `/images/landingPage/${path}.png`;
    },
    onClick(key) {
      this.curNav = key;
      if (key === "qrcode") {
        this.$emit("show-qrcode", true);
      } else if (key === "nav") {
        this.$emit("show-mobile-nav", true);
      } else if (key === "yj-offical-website") {
        window.open("https://changjing.com.cn");
      } else {
        this.$emit("on-select", key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~style/config.scss";
.header {
  @include nav_height;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
  .g-toolbar {
    margin-left: 30px;
    a {
      font-weight: 600;
      color: #fff;
      font-size: 16px;
    }
    .login {
      margin-right: 15px;
    }
    .free-try {
      display: inline-block;
      padding: 2px 16px;
      border-radius: 18px;
      background-color: #0064d2;
      transition: all 0.15s;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .g-phone {
    margin-left: 135px;
    .phone {
      position: relative;
      top: 2px;
      margin-right: 0 !important;
      width: 16px !important;
      height: 16px !important;
    }
    .phone-number {
      color: #fff;
      font-weight: 600;
      margin-left: 6px;
      font-size: 16px;
    }
  }
  .content {
    @include wrap_width;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      height: 40px;
      margin-right: 100px;
    }
    .nav {
      font-size: 16px;
      margin: 0 20px;
      font-weight: 600;
      color: #ffffff;
      &:hover {
        cursor: pointer;
      }
    }
    .cur {
      span {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 120%;
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
          width: 32px;
          border-bottom: 2px solid #ffffff;
        }
      }
    }
  }
  .mobile-content {
    @include wrap_width;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 8rem;
      height: 2.9rem;
    }
    .icons {
      display: flex;
      align-items: center;
      .icon {
        width: 2.2rem;
        height: 2.2rem;
        &:not(:last-child) {
          margin-right: 2rem;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.bg {
  background: rgba(0, 0, 0, 0.7);
}
.bg1 {
  background: #fff;
}
.mobile-height {
  @include mobile_nav_height;
  box-shadow: 0rem 0.2rem 0.5rem 0rem rgba(0, 0, 0, 0.06);
}
</style>
